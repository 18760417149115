/* Reset i zmienne */
:root {
  --primary: #fca311;
  --primary-light: rgba(252, 163, 17, 0.1);
  --background: #1a1a1a;
  --background-light: #242424;
  --text: rgba(255, 255, 255, 0.9);
  --text-muted: rgba(255, 255, 255, 0.6);
  --border: rgba(255, 255, 255, 0.1);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Podstawowe style */
html,
body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  background: linear-gradient(135deg, var(--background) 0%, var(--background-light) 100%);
  color: var(--text);
  line-height: 1.6;
  min-height: 100vh;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Kontenery */
.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* Typografia */
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  line-height: 1.2;
  margin-top: 3rem;
}

h1 {
  font-size: 3rem;
  background: linear-gradient(135deg, #ffffff, var(--primary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h2 {
  font-size: 2.5rem;
  color: var(--primary);
  margin-top: 1rem;
}

h3 {
  font-size: 2rem;
  margin-top: 1rem;
}

p {
  margin-bottom: 1.5rem;
  color: var(--text);
}

/* Linki */
a {
  color: var(--primary);
  text-decoration: none;
  transition: all 0.3s ease;
}

a:hover {
  color: var(--primary);
  opacity: 0.8;
}

/* Listy */
ul, ol {
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
}

li {
  margin-bottom: 0.5rem;
}

/* Przyciski */
button {
  background: linear-gradient(135deg, var(--primary), #e85d04);
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(252, 163, 17, 0.3);
}

/* Karty */
.card {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 20px;
  border: 1px solid var(--border);
  padding: 1.5rem;
  transition: all 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  border-color: rgba(252, 163, 17, 0.3);
  background: rgba(255, 255, 255, 0.05);
}

/* Obrazy */
img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Formularze */
input,
textarea {
  width: 100%;
  padding: 1rem 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--border);
  border-radius: 10px;
  color: var(--text);
  font-size: 1rem;
  transition: all 0.3s ease;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 2px rgba(252, 163, 17, 0.2);
}

/* Pomocnicze klasy */
.text-gradient {
  background: linear-gradient(135deg, #ffffff, var(--primary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.glass {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  border: 1px solid var(--border);
  border-radius: 20px;
}

/* Animacje */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.5s ease forwards;
}

/* Media Queries */
@media (max-width: 1200px) {
  .container {
    padding: 0 1.5rem;
  }
  
  h1 {
    font-size: 2.5rem;
  }
  
  h2 {
    font-size: 2rem;
  }
  
  h3 {
    font-size: 1.75rem;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0 1rem;
  }
  
  h1 {
    font-size: 2rem;
  }
  
  h2 {
    font-size: 1.75rem;
  }
  
  h3 {
    font-size: 1.5rem;
  }
  
  button {
    padding: 0.7rem 1.2rem;
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.75rem;
  }
  
  h2 {
    font-size: 1.5rem;
  }
  
  h3 {
    margin-top: 5px;
    font-size: 1.25rem;
  }
  
  p {
    font-size: 0.95rem;
  }
  
  .card {
    padding: 1rem;
  }
  
  input,
  textarea {
    padding: 0.8rem 1rem;
  }
}

/* Preferencje systemowe */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --background: #ffffff;
    --background-light: #f5f5f5;
    --text: #333333;
    --text-muted: #666666;
    --border: rgba(0, 0, 0, 0.1);
  }
  
  .glass {
    background: rgba(255, 255, 255, 0.8);
  }
  
  .card {
    background: rgba(255, 255, 255, 0.8);
  }
}